#ie-warning {
  margin: 20px;
  font-family: "Titillium Web";
  display: none;
}
body,
html,
#root {
  margin: 0;
  height: 100%;
}
